import {
	FETCH_USERS_SUCCESS,
	FETCH_USERS_ERROR,
	FETCH_USERS_LOADING,
	USERS_SEARCH_CHANGED,
	SWITCH_USERS_TAB,
	USER_CHANGE_BLOCK_STATUS,
	DELETE_USER,
} from "../actions/usersList";

const initialState = {
	data: null,
	tab: "carer",
	search: "",
	error: false,
	loading: false,
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_USERS_SUCCESS:
			return { ...state, data: payload, error: false, loading: false };
		case FETCH_USERS_ERROR:
			return { ...state, error: true, loading: false };
		case FETCH_USERS_LOADING:
			return { ...state, loading: true };
		case USERS_SEARCH_CHANGED:
			return { ...state, search: payload };
		case SWITCH_USERS_TAB:
			return {
				...state,
				tab: payload,
				data: initialState.data,
				search: initialState.search,
			};
		case USER_CHANGE_BLOCK_STATUS:
			return {
				...state,
				data: {
					...state.data,
					list: updateUserBlockStatusInList(payload.userId, [
						...state.data.list,
					]),
				},
			};
		case DELETE_USER:
			return {
				...state,
				data: {
					...state.data,
					list: deleteUserFromList(payload, [...state.data.list]),
				},
			};
		default:
			return state;
	}
};

function deleteUserFromList(id, list) {
	return list.filter(user => {
		return user.id !== id;
	});
}

function updateUserBlockStatusInList(userId, userList) {
	userList.forEach(user => {
		if (user.id === userId) {
			user.blocked = !user.blocked;
		}
	});

	return userList;
}

export default reducer;
