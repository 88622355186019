import React from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { blockCarer, unblockCarer } from "../../../../services/api";
import { changeUserBlockStatus } from "../../../../store/actions/usersList";

/**
 *
 */
const BlockButton = ({ user }) => {
	const token = useSelector(state => state.user.token);

	const dispatch = useDispatch();

	const toggleBlock = () => {
		if (user.blocked) {
			unblockCarer(user.id, token)
				.then(res => {
					toastr.success(res.data.message);

					dispatch(changeUserBlockStatus(user.id));
				})
				.catch(e => toastr.error(e.message));
		} else {
			blockCarer(user.id, token)
				.then(res => {
					toastr.success(res.data.message);

					dispatch(changeUserBlockStatus(user.id));
				})
				.catch(e => toastr.error(e.message));
		}
	};

	return (
		<Button variant={"warning"} size="sm" onClick={toggleBlock}>
			{user.blocked ? "Unblock" : "Block"}
		</Button>
	);
};

export default BlockButton;
