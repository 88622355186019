import React from "react";
import config from "../config";
import { Route, Redirect } from "react-router-dom";
import AdminLayout from "../App/layout/AdminLayout";
import UsersList from "../App/components/UsersList";

const AdminRoute = () => {
	return (
		<>
			<Route
				path={config.defaultPath}
				exact
				render={() => (
					<AdminLayout>
						<UsersList />
					</AdminLayout>
				)}
			/>
			<Redirect from="/" to={config.defaultPath} />
		</>
	);
};

export default AdminRoute;
