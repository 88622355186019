import { signIn } from "../../services/api";
import { storeUserData } from "../../utils/authentication";
import { toastr } from "react-redux-toastr";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const loginSuccess = payload => ({
	type: LOGIN_SUCCESS,
	payload,
});

export const userLogout = () => ({
	type: LOGOUT,
});

export const loginUser = (email, password) => {
	return async dispatch => {
		try {
			const response = await signIn(email, password);

			storeUserData(response.data);
			dispatch(loginSuccess(response.data));
		} catch (e) {
			switch (e.response.status) {
				case 401:
					return toastr.error("Error!", e.response.data.message);
				default:
					return toastr.error(
						"Error!",
						"Something went wrong :( Please try again later"
					);
			}
		}
	};
};
