import React from "react";
import { Table as BTable } from "react-bootstrap";
import { useTable, useSortBy } from "react-table";

/**
 *
 */
function Table({ columns, data, tableProps }) {
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useSortBy
	);

	return (
		<BTable {...tableProps} {...getTableProps()}>
			<thead>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps(column.getSortByToggleProps())}>
								{column.render("Header")}
								<span>
									{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
								</span>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map(cell => {
								return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
							})}
						</tr>
					);
				})}
			</tbody>
		</BTable>
	);
}

export default Table;
