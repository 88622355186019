import axios from "axios";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

export function signIn(email, password) {
	return api.get("/signin", {
		params: {
			email,
			password,
		},
	});
}

export function getUsers(params, token) {
	return api.get("/carers", {
		params,
		headers: {
			"x-access-token": token,
		},
	});
}

export function deleteUser(id, token) {
	return api.delete(`/carers/${id}`, {
		headers: {
			"x-access-token": token,
		},
	});
}

export function blockCarer(id, token) {
	return api.patch(`/carer/${id}/block`, null, {
		headers: {
			"x-access-token": token,
		},
	});
}

export function unblockCarer(id, token) {
	return api.patch(`/carer/${id}/unblock`, null, {
		headers: {
			"x-access-token": token,
		},
	});
}

export function getElders(params, token) {
	return api.get("/elders", {
		params,
		headers: {
			"x-access-token": token,
		},
	});
}

export function resetAdminPassword(params) {
	return api.get("/admin/reset-password", { params });
}

export function changeAdminPassword(body) {
	return api.post("/admin/change-password", body);
}

export function resetCarerPassword(params) {
	return api.get("/carer/reset-password", { params });
}

export function changeCarerPassword(body) {
	return api.post("/carer/change-password", body);
}
