import React, { Component } from "react";

// styles
import "font-awesome/scss/font-awesome.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/scss/style.scss";
import "react-redux-toastr/src/styles/index.scss";

// modules
import { connect } from "react-redux";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import Router from "../router";
import { loginSuccess } from "../store/actions/user";
import Toastr from "react-redux-toastr";

/**
 *
 */
class App extends Component {
	render() {
		const { user } = this.props;

		return (
			<Aux>
				<ScrollToTop>
					<Router isAuthenticated={!!user} />
				</ScrollToTop>

				<Toastr
					timeOut={4000}
					newestOnTop={false}
					preventDuplicates
					position="top-left"
					getState={state => state.toastr} // This is the default
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					progressBar
					closeOnToastrClick
				/>
			</Aux>
		);
	}
}

const mapState = state => {
	const { user } = state;

	return { user };
};

const mapDispatch = dispatch => {
	return {
		setUserData: data => dispatch(loginSuccess(data)),
	};
};

export default connect(mapState, mapDispatch)(App);
