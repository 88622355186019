import React from "react";
import Aux from "../../../hoc/_Aux";
import { Pagination } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchUsers } from "../../../store/actions/usersList";
import Loader from "../../layout/Loader";
import Table from "../Table";
import columns from "./columns/carer";

/**
 *
 */
class Carer extends React.Component {
	/**
	 *
	 * @param {*} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			page: 1,
			search: "",
		};

		this.getPaginationItems = this.getPaginationItems.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		const { getUsers, search } = this.props;

		getUsers({
			page: this.state.page,
			search,
		});
	}

	/**
	 *
	 * @param {*} number
	 */
	handlePageChange(number) {
		const { getUsers, search } = this.props;

		return () => {
			getUsers({
				page: number,
				search,
			});

			this.setState({ page: number });
		};
	}

	/**
	 *
	 */
	getPaginationItems() {
		const {
			users: { pagesAmount },
		} = this.props;

		const items = [];

		for (let number = 1; number <= pagesAmount; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === this.state.page}
					onClick={
						number === this.state.page ? null : this.handlePageChange(number)
					}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	}

	/**
	 *
	 */
	render() {
		const { users, loading, error } = this.props;

		if (error || !users || !users.list) {
			if (loading) {
				return (
					<>
						<Loader />
						<div>Loading..</div>
					</>
				);
			}
			return <div>Nothing found</div>;
		}

		return (
			<Aux>
				{loading ? <Loader /> : null}

				<Table
					columns={columns}
					data={users.list}
					tableProps={{
						bordered: true,
						hover: true,
						size: "md",
					}}
				/>

				<Pagination style={{ flexWrap: "wrap" }}>
					{this.getPaginationItems()}
				</Pagination>
			</Aux>
		);
	}
}

const mapState = state => {
	const {
		usersList: { data, loading, error, search },
	} = state;

	return {
		users: data,
		loading,
		error,
		search,
	};
};

const mapDispatch = dispatch => {
	return {
		getUsers: params => dispatch(fetchUsers(params)),
	};
};

export default connect(mapState, mapDispatch)(Carer);
