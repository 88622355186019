import React from "react";
import { Link } from "react-router-dom";
import Aux from "../../../hoc/_Aux";
import Breadcrumb from "../../layout/AdminLayout/Breadcrumb";
import { connect } from "react-redux";
import { loginUser } from "../../../store/actions/user";

class SignIn extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			login: "",
			password: "",
		};

		this.onInputChange = this.onInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	onInputChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	handleSubmit(e) {
		e.preventDefault();

		const { login, password } = this.state;
		const { signIn } = this.props;

		signIn(login, password);
	}

	render() {
		return (
			<Aux>
				<Breadcrumb />
				<div className="auth-wrapper">
					<div className="auth-content">
						<div className="auth-bg">
							<span className="r" />
							<span className="r s" />
							<span className="r s" />
							<span className="r" />
						</div>
						<div className="card">
							<div className="card-body text-center">
								<div className="mb-4">
									<i className="feather icon-unlock auth-icon" />
								</div>
								<h3 className="mb-4">Login</h3>
								<form onSubmit={this.handleSubmit}>
									<div className="input-group mb-3">
										<input
											type="text"
											name="login"
											className="form-control"
											placeholder="Email"
											onChange={this.onInputChange}
											value={this.state.login}
										/>
									</div>
									<div className="input-group mb-4">
										<input
											type="password"
											name="password"
											className="form-control"
											placeholder="password"
											onChange={this.onInputChange}
											value={this.state.password}
										/>
									</div>
									<button
										className="btn btn-primary shadow-2 mb-4"
										type="submit"
									>
										Login
									</button>
								</form>

								<p className="mb-2 text-muted">
									{"Forgot password? "}

									<Link to="/reset">Reset</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</Aux>
		);
	}
}

const mapDispatch = dispatch => {
	return {
		signIn: (email, password) => dispatch(loginUser(email, password)),
	};
};

export default connect(null, mapDispatch)(SignIn);
