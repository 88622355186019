import React from "react";
import { Link } from "react-router-dom";
import Aux from "../../../hoc/_Aux";
import Breadcrumb from "../../layout/AdminLayout/Breadcrumb";
import { resetAdminPassword } from "../../../services/api";
import { toastr } from "react-redux-toastr";

class Reset extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: "",
			success: false,
		};

		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleEmailChange(e) {
		this.setState({
			email: e.target.value,
		});
	}

	handleSubmit(e) {
		e.preventDefault();

		resetAdminPassword({ email: this.state.email })
			.then(response => {
				toastr.success(response.data.message);
				this.setState({ success: true });
			})
			.catch(e => toastr.error(e.message));
	}

	render() {
		return (
			<Aux>
				<Breadcrumb />
				<div className="auth-wrapper">
					<div className="auth-content">
						<div className="auth-bg">
							<span className="r" />
							<span className="r s" />
							<span className="r s" />
							<span className="r" />
						</div>
						<div className="card">
							<div className="card-body text-center">
								<div className="mb-4">
									<i className="feather icon-unlock auth-icon" />
								</div>
								<h3 className="mb-4">Reset password</h3>
								
								{!this.state.success && (<form onSubmit={this.handleSubmit}>
									<div className="input-group mb-3">
										<input
											type="text"
											name="login"
											className="form-control"
											placeholder="Email"
											onChange={this.handleEmailChange}
											value={this.state.login}
										/>
									</div>

									<button
										className="btn btn-primary shadow-2 mb-4"
										type="submit"
									>
										Reset
									</button>
								</form>)}

								<p className="mb-2 text-muted">
									<Link to="/signin">Sign in</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</Aux>
		);
	}
}

export default Reset;
