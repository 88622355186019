import { LOGIN_SUCCESS, LOGOUT } from "../actions/user";
import { restoreUserData, deleteUserData } from "../../utils/authentication";

const initialState = restoreUserData();

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return { ...action.payload };
		case LOGOUT:
			return deleteUserData();
		default:
			return state;
	}
};

export default reducer;
