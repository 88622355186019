import moment from "moment";

const elderColumns = [
	{
		Header: "Name",
		accessor: "display_name",
	},
	{
		Header: "Role",
		accessor: "role",
	},
	{
		Header: "Connecting code",
		accessor: "onboarding_code",
	},
	{
		Header: "Created at",
		accessor: "created_date",
		Cell: ({ value }) => {
			return moment(value).format("DD/MM/yyyy");
		},
	},
];

export default elderColumns;
