import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Carer from "./Carer";
import Elder from "./Elder";
import { connect } from "react-redux";
import { switchUsersTabAction } from "../../../store/actions/usersList";

/**
 *
 */
class UsersList extends React.Component {
	changeTab = tab => {
		this.props.switchTab(tab);
	};

	render() {
		return (
			<Tabs
				activeKey={this.props.currentTab}
				onSelect={tab => this.changeTab(tab)}
				mountOnEnter={true}
				unmountOnExit={true}
			>
				<Tab eventKey="carer" title="Carers">
					<Carer />
				</Tab>

				<Tab eventKey="elder" title="Elders">
					<Elder />
				</Tab>
			</Tabs>
		);
	}
}

const mapState = state => ({ currentTab: state.usersList.tab });

const mapDispatch = dispatch => ({
	switchTab: tab => dispatch(switchUsersTabAction(tab)),
});

export default connect(mapState, mapDispatch)(UsersList);
