import React, { Component } from "react";
import windowSize from "react-window-size";

import Aux from "../../../../../../hoc/_Aux";
import { connect } from "react-redux";

import {
	changeUsersSearch,
	fetchUsers,
} from "../../../../../../store/actions/usersList";

class NavSearch extends Component {
	state = {
		searchWidth: this.props.windowWidth < 992 ? 90 : 0,
		searchString: this.props.windowWidth < 992 ? "90px" : "",
		isOpen: this.props.windowWidth < 992,
	};

	submitSearch = e => {
		e.preventDefault();

		if (this.state.isOpen) {
			this.props.getUsers({
				page: 1,
				search: this.props.search,
			});
		}
	};

	searchOnHandler = () => {
		if (this.state.isOpen) {
			this.props.getUsers({
				page: 1,
				search: this.props.search,
			});
		}

		this.setState({ isOpen: true });
		const searchInterval = setInterval(() => {
			if (this.state.searchWidth >= 91) {
				clearInterval(searchInterval);
				return false;
			}
			this.setState(prevSate => {
				return {
					searchWidth: prevSate.searchWidth + 15,
					searchString: prevSate.searchWidth + "px",
				};
			});
		}, 35);
	};

	searchOffHandler = () => {
		const searchInterval = setInterval(() => {
			this.props.clearSearch();

			this.props.getUsers({
				page: 1,
			});

			if (this.state.searchWidth < 0) {
				this.setState({ isOpen: false });
				clearInterval(searchInterval);
				return false;
			}
			this.setState(prevSate => {
				return {
					searchWidth: prevSate.searchWidth - 15,
					searchString: prevSate.searchWidth + "px",
				};
			});
		}, 35);
	};

	render() {
		const { search, searchChange } = this.props;

		let searchClass = ["main-search"];
		if (this.state.isOpen) {
			searchClass = [...searchClass, "open"];
		}

		return (
			<Aux>
				<form
					onSubmit={this.submitSearch}
					id="main-search"
					className={searchClass.join(" ")}
				>
					<div className="input-group">
						<input
							value={search}
							onChange={searchChange}
							type="text"
							id="m-search"
							className="form-control"
							placeholder="Search . . ."
							style={{ width: this.state.searchString }}
						/>

						<div
							className="input-group-append search-close"
							onClick={this.searchOffHandler}
						>
							<i className="feather icon-x input-group-text" />
						</div>

						<span
							className="input-group-append search-btn btn btn-primary"
							onClick={this.searchOnHandler}
						>
							<i className="feather icon-search input-group-text" />
						</span>
					</div>
				</form>
			</Aux>
		);
	}
}

const mapState = state => ({
	search: state.usersList.search,
});

const mapDispatch = dispatch => ({
	searchChange: event => dispatch(changeUsersSearch(event.target.value)),
	clearSearch: () => dispatch(changeUsersSearch("")),
	getUsers: params => dispatch(fetchUsers(params)),
});

export default connect(mapState, mapDispatch)(windowSize(NavSearch));
