import React from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { deleteUser } from "../../../../services/api";
import { deleteUserFromList } from "../../../../store/actions/usersList";
import { showRequestErrorMessage } from "../../../../utils/api";

/**
 *
 */
const DeleteButton = ({ userId }) => {
	const token = useSelector(state => state.user.token);

	const dispatch = useDispatch();

	const handleDelete = () => {
		deleteUser(userId, token)
			.then(res => {
				toastr.success(res.data.message);

				dispatch(deleteUserFromList(userId));
			})
			.catch(e => showRequestErrorMessage(e));
	};

	return (
		<Button variant={"danger"} size="sm" onClick={handleDelete}>
			{"Delete"}
		</Button>
	);
};

export default DeleteButton;
