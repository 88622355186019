import React from "react";
import { Button } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { resetCarerPassword } from "../../../../services/api";

/**
 *
 */
const RestoreButton = ({ email }) => {
	const handleClick = () => {
		resetCarerPassword({ email })
			.then(res => toastr.success(res.data.message))
			.catch(e => toastr.error(e.message));
	};

	return (
		<Button variant={"success"} size="sm" onClick={handleClick}>
			Restore
		</Button>
	);
};

export default RestoreButton;
