import { combineReducers } from "redux";
import ui from "./ui";
import user from "./user";
import usersList from "./usersList";
import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
	ui,
	user,
	toastr,
	usersList,
});
