import React from "react";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import Aux from "../../../hoc/_Aux";
import {
	changeAdminPassword,
	changeCarerPassword,
} from "../../../services/api";
import Breadcrumb from "../../layout/AdminLayout/Breadcrumb";

class ChangePassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			password: "",
			success: false,
		};

		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handlePasswordChange(e) {
		this.setState({
			password: e.target.value,
		});
	}

	handleSubmit(e) {
		e.preventDefault();

		if (this.props.match.params.role === "user") {
			return changeCarerPassword({
				password: this.state.password,
				token: this.props.match.params.token,
				userId: this.props.match.params.userId,
			})
				.then(response => {
					toastr.success(response.data.message);
					this.setState({ success: true });
				})
				.catch(e => toastr.error(e.message));
		}

		return changeAdminPassword({
			password: this.state.password,
			token: this.props.match.params.token,
			userId: this.props.match.params.userId,
		})
			.then(response => {
				toastr.success(response.data.message);
				this.setState({ success: true });
			})
			.catch(e => toastr.error(e.message));
	}

	render() {
		const isUser = this.props.match.params.role === "user";

		return (
			<Aux>
				<Breadcrumb />
				<div className="auth-wrapper">
					<div className="auth-content">
						<div className="auth-bg">
							<span className="r" />
							<span className="r s" />
							<span className="r s" />
							<span className="r" />
						</div>
						<div className="card">
							<div className="card-body text-center">
								<div className="mb-4">
									<i className="feather icon-unlock auth-icon" />
								</div>
								<h3 className="mb-4">{this.state.success ? "Success!" : "Change password"}</h3>

								{!this.state.success && (
									<form onSubmit={this.handleSubmit}>
										<div className="input-group mb-3">
											<input
												type="password"
												name="password"
												className="form-control"
												placeholder="New password"
												onChange={this.handlePasswordChange}
												value={this.state.login}
											/>
										</div>

										<button
											className="btn btn-primary shadow-2 mb-4"
											type="submit"
										>
											Send
										</button>
									</form>
								)}

								{this.state.success && (
									<h4 style={{color: "#0db834"}}>You have successfully changed your password!</h4>
								)}

								{!isUser && (
									<p className="mb-2 mt-4 text-muted">
										<Link to="/signin">Sign in</Link>
									</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</Aux>
		);
	}
}

export default ChangePassword;
