import React, { Component } from "react";
import { connect } from "react-redux";
import Fullscreen from "react-full-screen";
import windowSize from "react-window-size";
import Navigation from "./Navigation";
import NavBar from "./NavBar";
import Breadcrumb from "./Breadcrumb";
import Aux from "../../../hoc/_Aux";
import * as actionTypes from "../../../store/actions/ui";

class AdminLayout extends Component {
	fullScreenExitHandler = () => {
		if (
			!document.fullscreenElement &&
			!document.webkitIsFullScreen &&
			!document.mozFullScreen &&
			!document.msFullscreenElement
		) {
			this.props.onFullScreenExit();
		}
	};

	componentDidMount() {
		if (
			this.props.windowWidth > 992 &&
			this.props.windowWidth <= 1024 &&
			this.props.layout !== "horizontal"
		) {
			this.props.onComponentWillMount();
		}
	}

	mobileOutClickHandler() {
		if (this.props.windowWidth < 992 && this.props.collapseMenu) {
			this.props.onComponentWillMount();
		}
	}

	render() {
		/* full screen exit call */
		document.addEventListener("fullscreenchange", this.fullScreenExitHandler);
		document.addEventListener(
			"webkitfullscreenchange",
			this.fullScreenExitHandler
		);
		document.addEventListener(
			"mozfullscreenchange",
			this.fullScreenExitHandler
		);
		document.addEventListener("MSFullscreenChange", this.fullScreenExitHandler);

		return (
			<Aux>
				<Fullscreen enabled={this.props.isFullScreen}>
					<Navigation />
					<NavBar />
					<div
						className="pcoded-main-container"
						onClick={() => this.mobileOutClickHandler}
					>
						<div className="pcoded-wrapper">
							<div className="pcoded-content">
								<div className="pcoded-inner-content">
									<Breadcrumb />
									<div className="main-body">
										<div className="page-wrapper">{this.props.children}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fullscreen>
			</Aux>
		);
	}
}

const mapStateToProps = state => {
	return {
		defaultPath: state.ui.defaultPath,
		isFullScreen: state.ui.isFullScreen,
		collapseMenu: state.ui.collapseMenu,
		configBlock: state.ui.configBlock,
		layout: state.ui.layout,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onFullScreenExit: () => dispatch({ type: actionTypes.FULL_SCREEN_EXIT }),
		onComponentWillMount: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(windowSize(AdminLayout));
