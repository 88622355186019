import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SignIn from "../App/components/Authentication/SignIn.js";
import Reset from "../App/components/Authentication/Reset.js";
import ChangePassword from "../App/components/Authentication/ChangePassword.js";
// import SignUp from "./App/components/Authentication/SignUp";

const GuestRoute = () => {
	return (
		<Switch>
			<Route path="/signin" component={SignIn} exact />
			<Route path="/reset" component={Reset} exact />
			<Route
				path="/change-password/:userId/:token/:role"
				component={ChangePassword}
				exact
			/>
			<Route
				path="/change-password/:userId/:token"
				component={ChangePassword}
				exact
			/>
			{/* <Route path="/signup" component={SignUp} /> */}
			<Redirect from="/" to={"/signin"} />
		</Switch>
	);
};

export default GuestRoute;
