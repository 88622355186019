import { getUsers, getElders } from "../../services/api";
import { deleteUserData } from "../../utils/authentication";
import { showRequestErrorMessage } from "../../utils/api";

export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";
export const FETCH_USERS_LOADING = "FETCH_USERS_LOADING";
export const USERS_SEARCH_CHANGED = "USERS_SEARCH_CHANGED";
export const SWITCH_USERS_TAB = "SWITCH_USERS_TAB";
export const USER_CHANGE_BLOCK_STATUS = "USER_CHANGE_BLOCK_STATUS";
export const DELETE_USER = "DELETE_USER";

export const deleteUserFromList = id => ({
	type: DELETE_USER,
	payload: id,
});

export const switchUsersTabAction = tab => ({
	type: SWITCH_USERS_TAB,
	payload: tab,
});

export const changeUserBlockStatus = userId => ({
	type: USER_CHANGE_BLOCK_STATUS,
	payload: { userId },
});

export const changeUsersSearch = value => ({
	type: USERS_SEARCH_CHANGED,
	payload: value,
});

export const fetchUsers = params => {
	return async (dispatch, getState) => {
		dispatch({ type: FETCH_USERS_LOADING });

		const state = getState();

		try {
			let response;

			if (state.usersList.tab === "carer") {
				response = await getUsers(params, state.user.token);
			} else {
				response = await getElders(params, state.user.token);
			}

			dispatch({ type: FETCH_USERS_SUCCESS, payload: response.data });
		} catch (e) {
			dispatch({ type: FETCH_USERS_ERROR });

			showRequestErrorMessage(e);

			if (e.response && e.response.status === 401) {
				deleteUserData();
				document.location = "/";
			}
		}
	};
};
