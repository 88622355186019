const menu = {
	items: [
		{
			id: "menu",
			title: "Navigation",
			type: "group",
			icon: "icon-navigation",
			children: [
				{
					id: "users",
					title: "User Management",
					type: "item",
					url: "/users",
					icon: "feather icon-home",
				},
			],
		},
	],
};

export default menu;
