import React from "react";
import Aux from "../../../../../hoc/_Aux";
import { Link } from "react-router-dom";

const navLogo = props => {
	return (
		<Aux>
			<div className="navbar-brand header-logo">
				<Link to="/" className="b-brand">
					<div className="b-bg">
						<i className="feather icon-trending-up" />
					</div>
					<span className="b-title">Loopeli</span>
				</Link>
			</div>
		</Aux>
	);
};

export default navLogo;
