import moment from "moment";
import BlockButton from "../buttons/Block";
import DeleteButton from "../buttons/Delete";
import RestoreButton from "../buttons/Restore";
import Status from "../Status";

/**
 *
 * @param {*} user
 */
const getUserStatus = user => {
	if (user.blocked) {
		return "blocked";
	}

	if (!user.active) {
		return "inactive";
	}

	return "active";
};

/**
 *
 */
const carerColumns = [
	{
		Header: "Name",
		accessor: "display_name",
		Cell: ({ value, row }) => (
			<div
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Status status={getUserStatus(row.original)} />

				{value || "-"}
			</div>
		),
	},
	{
		Header: "Role",
		accessor: "role",
		disableSortBy: false,
	},
	{
		Header: "Email",
		accessor: "email",
	},
	{
		Header: "Created at",
		accessor: "created_date",
		Cell: ({ value, row }) => {
			return moment(value).format("DD/MM/yyyy");
		},
	},
	{
		Header: "Password",
		id: "password",
		Cell: ({ row }) => <RestoreButton email={row.original.email} />,
	},
	{
		Header: "Block",
		id: "block",
		Cell: ({ row }) => {
			return <BlockButton user={row.original} />;
		},
	},
	{
		Header: "Delete",
		id: "delete",
		Cell: ({ row }) => {
			return <DeleteButton userId={row.original.id} />;
		},
	},
];

export default carerColumns;
