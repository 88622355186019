import React, { Component } from "react";
import { connect } from "react-redux";
import windowSize from "react-window-size";

import NavSearch from "./NavSearch";
import Aux from "../../../../../hoc/_Aux";
import * as actionTypes from "../../../../../store/actions/ui";

class NavLeft extends Component {
	render() {
		return (
			<Aux>
				<ul className="navbar-nav ml-auto">
					<li className="nav-item">
						<NavSearch />
					</li>
				</ul>
			</Aux>
		);
	}
}

const mapStateToProps = state => {
	return {
		isFullScreen: state.ui.isFullScreen,
		rtlLayout: state.ui.rtlLayout,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onFullScreen: () => dispatch({ type: actionTypes.FULL_SCREEN }),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(windowSize(NavLeft));
