import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { BrowserRouter } from "react-router-dom";
import App from "./App/index";
import reducer from "./store/reducer";
import config from "./config";

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

const app = (
	<Provider store={store}>
		<BrowserRouter basename={config.basename}>
			<App />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById("root"));
