import React from "react";
import css from "./Status.module.scss";

const Status = ({ status }) => {
	const statusColor = {
		active: css.green,
		inactive: css.yellow,
		blocked: css.red
	};

	return <div className={`${css.index} ${statusColor[status]}`} />;
};

export default Status;
